'use strict';

// Stores information for use in all other scripts
const pageDetails = Object.freeze({
    fileName: location.pathname.split('/').pop()
});

// Set the 'active' class on the link within a navigation list based on the current page
const setActiveLink = function(linkList) {
    const indexPage = 'home.html' // The page designated to be the index

    if (pageDetails.fileName.length > 0) {
        linkList.forEach((link) => {
            if (link.getAttribute('href') === pageDetails.fileName) {
                link.classList.add('active');
                return;
            }
        });

    } else {
        // Add class active to the index page specified above
        linkList.forEach((link) => {
            if (link.getAttribute('href') === indexPage) {
                link.classList.add('active');
                return;
            }
        });
    }
}

const navBar = function() {
    const navDropDown = document.getElementById('nav-drop-down');
    const menuIcon = navDropDown.firstChild;

    const navBarList = navDropDown.nextElementSibling; // The 'ul' element
    const navBarListItems = Array.from(navBarList.getElementsByTagName('li')); // The 'li' elements
    const navBarLinks = navBarListItems.map((li) => li.firstChild); // The 'a' elements

    // Set the active class on the links in the navbar list
    setActiveLink(navBarLinks);

    let dropDownOpen = false;
    navDropDown.addEventListener('click', () => {
        // Animate hamburger menu icon with drop down
        menuIcon.classList.toggle('open');

        // Animate nav bar list into or out of view
        if (!dropDownOpen) {
            anime.remove(navDropDown);
            dropDownOpen = true;
            anime({
                targets: navBarList,
                height: (navBarListItems[0].scrollHeight * 5),
                duration: 300,
                easing: 'easeOutQuad',
            });

        } else {
            anime.remove(navDropDown);
            dropDownOpen = false;
            anime({
                targets: navBarList,
                height: 0,
                duration: 300,
                easing: 'easeOutQuad',
            });

        }
    });

    navBarLinks.forEach((link) => {
        if (link.className !== 'active') {
            link.addEventListener('mouseenter', () => {
                anime.remove(link);
                anime({
                    targets: link,
                    backgroundColor: '#00A62D',
                    color: '#FFFFFF',
                    duration: 300,
                    easing: 'linear'
                });
            });

            link.addEventListener('mouseleave', () => {
                anime.remove(link);
                anime({
                    targets: link,
                    backgroundColor: window.innerWidth >= 720 ? '#FF642D' : '#FFFFB4',
                    color: window.innerWidth >= 720 ? '#FFFFFF' : '#444444',
                    duration: 300,
                    easing: 'linear'
                });
            });
        }
    });

    // Reset styles to defaults if window is resized above 720
    window.addEventListener('resize', () => {
        if (window.innerWidth >= 720) {
            navBarList.style.height = '';
            navBarLinks.forEach((link) => {
                link.style.backgroundColor = '';
                link.style.color = '';
            });
        }
    });
}

const footer = function() {
    const footerNav = document.getElementById('footer-nav');
    const footerNavLinks = Array.from(footerNav.getElementsByTagName('a'));
    setActiveLink(footerNavLinks);
}

window.addEventListener('load', () => {
    navBar();
    footer();
});