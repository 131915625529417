const homeInteract = function() {
    const slides = Array.from(document.getElementsByClassName("slide"));
    let index = 0;

    setInterval(() => {
        index += 1;
        if (index >= slides.length) {
            index = 0;
        }

        slides.forEach((slide, i) => {
            if (i === index) {
                slide.style.display = 'block';
            } else {
                slide.style.display = 'none';
            }
        });
    }, 8000);

    // Defer the home page video resources so the page loads faster
    const videos = Array.from(document.getElementsByTagName('iframe'));
    videos.forEach((video) => {
        if (video.getAttribute('data-src')) {
            video.setAttribute('src', video.getAttribute('data-src'));
        } else {
            console.err(`${video} source data unavailable`);
        }
    });
}

if (pageDetails.fileName === 'home.html' || pageDetails.fileName === 'home') window.addEventListener('load', () => {
    homeInteract();
});

// // ------------------------------- Tumblr Stuff ------------------------------- 
// // When ready to reintroduce, add into the main home() method.
// // Requires jquery

// var titlehtml;
// var post1;
// var post2;
// var post3;
// var post4;
// var imgTemp;
// var image;
// $(document).ready(function() {

//     /* Modify first Tumblr blog post to act as a Modal */
//     post1 = $(".tumblr_post:eq(0)");

//     if (typeof post1 !== null) {
//         $(".tumblr_post:eq(0)").wrap('<div class="Post" onClick="showModal(\'tModal1\');"> </div>');


//         image = $(".tumblr_post:eq(0) img:eq(0)");

//         if (image.attr('width') > 0) {
//             imgTemp = document.createElement("img");

//             imgTemp = ($(".tumblr_post:eq(0) img:first").clone());

//             imgTemp.addClass("PostPic");

//             $(".tumblr_title:eq(0)").after(imgTemp);
//         } else {
//             $(".tumblr_title:eq(0)").after(
//                 '<img class="PostPic" src="images/Modal_Box/About_Page/Five_Star_Power_Systems_Is_A_Certified_Cummins_Dealer_in_Maryland.png" alt="Five Star Power Systems is a certified Cummins Generator Dealer in Maryland.jpg">');

//         }

//         $(".tumblr_title:eq(0)").wrap("<h4 style='padding-top:10px;'> </h4>");


//         $(".tumblr_body:eq(0)").wrap('<div id="tModal1" class="modal" onClick="hideModal(this);"> </div>');

//         $(".tumblr_body:eq(0)").attr('class', 'tumblr_body modal-content');
//         titlehtml = document.createElement("h3");
//         titlehtml.innerHTML = ($(".tumblr_title:eq(0)").html());
//         $(".tumblr_body:eq(0)").prepend(titlehtml);
//         $(".tumblr_body:eq(0)").prepend('<span class="close">&times;</span>');

//     }

//     /* Modify second Tumblr blog post to act as a Modal */
//     post2 = $(".tumblr_post:eq(1)");

//     if (typeof post2 !== null) {

//         $(".tumblr_post:eq(1)").wrap('<div class="Post" onClick="showModal(\'tModal2\');"> </div>');



//         image = $(".tumblr_post:eq(1) img:eq(0)");

//         if (image.attr('width') > 0) {

//             imgTemp = document.createElement("img");

//             imgTemp = ($(".tumblr_post:eq(1) img:first").clone());

//             imgTemp.addClass("PostPic");

//             $(".tumblr_title:eq(1)").after(imgTemp);
//         } else {

//             $(".tumblr_title:eq(1)").after(
//                 '<img class="PostPic" src="images/Modal_Box/About_Page/Five_Star_Power_Systems_Is_A_Certified_Cummins_Dealer_in_Maryland.png" alt="Five Star Power Systems is a certified Cummins Generator Dealer in Maryland.jpg">');

//         }

//         $(".tumblr_title:eq(1)").wrap("<h4 style='padding-top:10px;'> </h4>");


//         $(".tumblr_body:eq(1)").wrap('<div id="tModal2" class="modal" onClick="hideModal(this);"> </div>');

//         $(".tumblr_body:eq(1)").attr('class', 'tumblr_body modal-content');
//         titlehtml = document.createElement("h3");
//         titlehtml.innerHTML = ($(".tumblr_title:eq(1)").html());
//         $(".tumblr_body:eq(1)").prepend(titlehtml);
//         $(".tumblr_body:eq(1)").prepend('<span class="close">&times;</span>');

//     }

//     /* Modify third Tumblr blog post to act as a Modal */
//     post3 = $(".tumblr_post:eq(2)");

//     if (typeof post3 !== null) {

//         $(".tumblr_post:eq(2)").wrap('<div class="Post" onClick="showModal(\'tModal3\');"> </div>');


//         image = $(".tumblr_post:eq(2) img:eq(0)");

//         if (image.attr('width') > 0) {

//             imgTemp = document.createElement("img");

//             imgTemp = ($(".tumblr_post:eq(2) img:first").clone());

//             imgTemp.addClass("PostPic");

//             $(".tumblr_title:eq(2)").after(imgTemp);
//         } else {
//             $(".tumblr_title:eq(2)").after(
//                 '<img class="PostPic" src="images/Modal_Box/About_Page/Five_Star_Power_Systems_Is_A_Certified_Cummins_Dealer_in_Maryland.png" alt="Five Star Power Systems is a certified Cummins Generator Dealer in Maryland.jpg">');

//         }

//         $(".tumblr_title:eq(2)").wrap("<h4 style='padding-top:10px;'> </h4>");


//         $(".tumblr_body:eq(2)").wrap('<div id="tModal3" class="modal" onClick="hideModal(this);"> </div>');

//         $(".tumblr_body:eq(2)").attr('class', 'tumblr_body modal-content');
//         titlehtml = document.createElement("h3");
//         titlehtml.innerHTML = ($(".tumblr_title:eq(2)").html());
//         $(".tumblr_body:eq(2)").prepend(titlehtml);
//         $(".tumblr_body:eq(2)").prepend('<span class="close">&times;</span>');

//     }

//     /* Modify fourth Tumblr blog post to act as a Modal */
//     post4 = $(".tumblr_post:eq(3)");

//     if (typeof post4 !== null) {

//         $(".tumblr_post:eq(3)").wrap('<div class="Post" onClick="showModal(\'tModal4\');"> </div>');


//         image = $(".tumblr_post:eq(3) img:eq(0)");

//         if (image.attr('width') > 0) {

//             imgTemp = document.createElement("img");

//             imgTemp = ($(".tumblr_post:eq(3) img:first").clone());

//             imgTemp.addClass("PostPic");

//             $(".tumblr_title:eq(3)").after(imgTemp);
//         } else {
//             $(".tumblr_title:eq(3)").after(
//                 '<img class="PostPic" src="images/Modal_Box/About_Page/Five_Star_Power_Systems_Is_A_Certified_Cummins_Dealer_in_Maryland.png" alt="Five Star Power Systems is a certified Cummins Generator Dealer in Maryland.jpg">');

//         }
//         $(".tumblr_title:eq(3)").wrap("<h4 style='padding-top:10px;'> </h4>");


//         $(".tumblr_body:eq(3)").wrap('<div id="tModal4" class="modal" onClick="hideModal(this);"> </div>');

//         $(".tumblr_body:eq(3)").attr('class', 'tumblr_body modal-content');
//         titlehtml = document.createElement("h3");
//         titlehtml.innerHTML = ($(".tumblr_title:eq(2)").html());
//         $(".tumblr_body:eq(3)").prepend(titlehtml);
//         $(".tumblr_body:eq(3)").prepend('<span class="close">&times;</span>');

//     }
// });