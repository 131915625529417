const aboutInteract = function() {
    const aboutPosts = Array.from(document.getElementsByClassName('aboutPost'));
    const contactButton = aboutPosts.pop();
    contactButton.addEventListener('click', () => goContact());

    const modals = aboutPosts.map(post => post.querySelector('.modal'));

    aboutPosts.forEach((post, index) => {
        post.addEventListener('click', () => {
            showModal(index + '');
        });

        modals[index].addEventListener('click', () => {
            hideModal(index + '');
        });
    });

    let trigger = true;
    function showModal(index) {
        if (trigger) {
            modals[index].style.display = 'block';
            trigger = false;
        } else {
            trigger = true;
        }
    }

    function hideModal(index) {
        modals[index].style.display = '';
    }

    function goContact() { //Go to Contact Form
        open("contact.php", "_self");
    }
}

if (pageDetails.fileName === 'about.html'|| pageDetails.fileName === 'about') window.addEventListener('load', () => {
    aboutInteract();
});