const portfolioInteract = function() {
    const thumbnails = Array.from(document.getElementsByClassName('thumb'));

    const modals = Array.from(document.getElementsByClassName('modal'));
    const closeButtons =  modals.map(modal => modal.firstElementChild);
    const modalImages = modals.map(modal => modal.querySelector('img'));

    const prevButtons = Array.from(document.getElementsByClassName('prevBtn'));
    const nextButtons = Array.from(document.getElementsByClassName('nextBtn'));

    // Initialize all click handlers
    thumbnails.forEach((thumbnail, index) => {
        thumbnail.addEventListener('click', () => {
            showHideImage(index);
        });

        closeButtons[index].addEventListener('click', () => {
            showHideImage(index);
        });

        modalImages[index].addEventListener('click', () => {
            showHideImage(index);
        });

        prevButtons[index].addEventListener('click', () => {
            prev(index);
        });

        nextButtons[index].addEventListener('click', () => {
            next(index);
        });

    });

    function showHideImage(index) {
        if (modals[index].style.display === 'block') {
            modals[index].style.display = 'none';
        } else {
            modals[index].style.display = 'block';
        }
    }

    function prev(index) {
        let next = index - 1;
        if (next < 0) {
            next = modals.length - 1;
        }
        modals[index].style.display = 'none';
        modals[next].style.display = 'block';
    }

    function next(index) {
        let next = index + 1;
        if (next >= modals.length) {
            next = 0;
        }
        modals[index].style.display = 'none';
        modals[next].style.display = 'block';
    }
}

if (pageDetails.fileName === 'portfolio.html' || pageDetails.fileName === 'portfolio') window.addEventListener('load', () => {
    portfolioInteract();
});